
import "./index.scss";
import $ from "jquery";
import {
	preloadImages,
	preloadFonts,
	calcWinsize,
	adjustedBoundingRect
} from './utils';
import barba from '@barba/core';
import LazyLoad from "vanilla-lazyload";
import KUTE from 'kute.js'
import {
	toggleClass,
	select
} from './helpers.js';
//import vreel from "./vreel.js";
//import hscroll from "./hscroll.js";
import {
	gsap
} from "gsap";
import {
	ScrollTrigger
} from "gsap/ScrollTrigger";
import {
	ScrollToPlugin
} from "gsap/ScrollToPlugin";

const myLazyLoad = new LazyLoad({
	use_native: true, // <-- there you go
	unobserve_entered: false, // <- Avoid executing the function multiple times
	threshold: 0,
	callback_enter: executeLazyFunction // Assigning the function defined above
});

function executeLazyFunction(element) {
	// var lazyFunctionName = element.getAttribute("data-lazy-function");
	// var lazyFunction = window.lazyFunctions[lazyFunctionName];
	// if (!lazyFunction) return;
	// lazyFunction(element);
}

let winsize = calcWinsize();
window.addEventListener('resize', () => winsize = calcWinsize());

// After your content has changed...
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);


//Menu handling on event button push
const button = select('.c-navbar__menu');
const menu = select('.c-navbar');

// Create a tween for position first


var open_h = KUTE.fromTo('#h-closed', // target shape
	{
		path: '#h-closed'
	},
	{
		path: '#h-open'
	}, // from shape

	{ // options
		easing: 'easingCubicInOut',
		duration: 100
	}
)
var close_h = KUTE.fromTo('#h-closed', // target shape
	{
		path: '#h-open'
	},
	{
		path: '#h-closed'
	}, // from shape

	{ // options
		easing: 'easingCubicInOut',
		duration: 100
	}
)



window.addEventListener('DOMContentLoaded', function (e) {

	myLazyLoad.update();
	document.body.style.visibility = "visible";
	console.log("dom loaded")
	new Promise(function (resolve, reject) {
		setTimeout(() => resolve(1), 20); // (*)
	}).then(function (result) { // (**)
		document.body.classList.remove("loading")
	}).then(function () { // (***)
		console.log("loaded")
		document.body.classList.add("make_visible")

	})

	button.addEventListener('click', function (event) {
		console.log("clicked")
		//close menu
		if (button.classList.contains("is-active")) {

			menu.classList.remove("is-active");
			button.classList.remove("is-active");
			close_h.start();

			//open menu
		} else {
			open_h.start();
			menu.classList.add("is-active");
			button.classList.add("is-active");


			//gsap.to("#m-v", { duration: 1, morphSVG: "#m-h" });

			// !tween.playing && tween.fromTo('#star', {path: '#rectangle' }, { path: '#rectangle' }).start();
		}



	})
	// const leaveAnimation = (e) => {
	// 	return gsap.to(e, {
	// 		autoAlpha: 0, duration: 1, clearProps: 'all', ease: 'none'
	// 	})
	// }
	// const enterAnimation = (e) => {
	// 	return gsap.from(e, {
	// 		autoAlpha: 0, duration: 1, clearProps: 'all', ease: 'none'
	// 	})
	// }


	function leaveAnimation(e) {
		const tl = gsap.timeline();
		console.log("leave an")
		return new Promise(resolve => {
			tl
				.to(".page-transition__panel", {
					duration: 0.5,
					opacity: 1,
					scaleY: 1,
					transformOrigin: "top left"

				})

				.then(resolve());

		});
	}

	function enterAnimation(e) {

		console.log("enterAn")
		const tl = gsap.timeline();
		return new Promise(resolve => {
			tl
				.from(".page-transition__panel", {
					duration: 0.2,
					scaleY: 1,
					transformOrigin: "bottom left",
				})


				.to(".page-transition__panel", {
					delay: 0.5,
					duration: 0.3,
					scaleY: 0,
					transformOrigin: "top left",



				})

				.then(resolve());

		});
	}

	barba.init({
		debug: false,

		transitions: [{

			once: ({ next }) => enterAnimation(next.container),
			leave: ({ current }) => leaveAnimation(current.container),
			enter({ next }) {
				enterAnimation(next.container);
			}
		}],
		views: [{
			namespace: "prints",
			afterEnter: function (e) {
				// setTimeout(function () {
				// 	//imageView.Init(document.querySelector('.__grid__'));
				// }, 100)
			}
		},
		{
			namespace: "odir",
			afterEnter: function (e) {
				setTimeout(function () {
					Vreel.Init();
				}, 2)
			}
		}, {
			namespace: "project",
			afterEnter: function (e) {
				setTimeout(function () {
					//Vreel.Init();
				}, 2)
			}
		}

		]
	});

	barba.hooks.before((data) => {
		console.log("Babra before")
		//data.current.container.querySelector("container-fluid").style.opacity = "0"
		//console.log(data.next.container)
	})

	barba.hooks.enter((data) => {
		//data.next.container.querySelector("container-fluid").style.opacity = "0"
		console.log("Babra Enter")
	});

	barba.hooks.after(() => {
		console.log("Babra After")
		//afterAnimation(data)
		if (button.classList.contains("is-active")) {
			close_h.start();
			menu.classList.remove("is-active");
			button.classList.remove("is-active");
		}
	});

	barba.hooks.afterLeave(function (e) {
		console.log("after leave")
		ScrollTrigger.getAll().forEach(function (e) {
			return e.kill()
		})
	}),
		barba.hooks.beforeEnter((data) => {

			gsap.to(window, {
				scrollTo: 0
			});
			// data.current.container.querySelector("container-fluid").style.opacity = "1"

		})
	barba.hooks.afterEnter((data) => {
		//enterAnimation(data)
		myLazyLoad.update();
		setTimeout(function () {
			document.querySelectorAll('img[srcset]').forEach(function (img) {
				img.src += '';
			}, 1)
		});
	});

	setTimeout(function () {
		document.querySelectorAll('img[srcset]').forEach(function (img) {

			img.src += '';
			////console.log(img.src);// supported in browser

		}, 1)

	});
});


if ('loading' in HTMLImageElement.prototype) {
	////console.log("Native Lazy loading");// supported in browser
} else {
	////console.log("Polyfill Lazy loading");// supported in browser
}

var _copy = 2000;
Array.prototype.except = function (val) {
	return this.filter(function (x) { return x !== val; });
};


var Vreel = {
	Init: function (e) {

		this.Clone(), this.ItemTransition(), this.LoadMore()

	},
	Clone: function () {
		// gsap.utils.toArray(".cs-item-media:first-child").forEach(function(e) {
		// 	gsap.set(e, {
		// 		opacity: 1
		// 	})
		// });
		var item = $("#cs-items > div")

		item.clone().appendTo("#cs-items-cloned")


	},
	ItemTransition: function () {

		gsap.utils.toArray("#cs-items >div:last-of-type .cs-item").forEach(function (e) {
			const n = gsap.timeline({
				scrollTrigger: {
					trigger: e,
					pin: !1,
					scrub: 1,
					start: "top 80%",
					end: "bottom top"
				}
			});
			var i = e.querySelector(".cs-item-media-container"),
				e = e.querySelector(".cs-item-media");
			n.to(e, {
				duration: 1,
				opacity: 1
			}, 0),
				n.to(i, {
					duration: 1,
					scale: 0.78
				}, "-=1"),
				n.to(e, {
					duration: 1,
					opacity: 0
				}, "+=1")

		})
	},
	LoadMore: function () {

		ScrollTrigger.create({
			trigger: document.body,
			start: "top top",
			end: "bottom bottom",
			//	markers: true,
			onUpdate: e => {
				//
				if (.8 <= e.progress.toFixed(2) && 1 === e.direction) {
					//console.log("copy: " + _copy + "pos: " + e.progress)
					_copy -= 1;
					$("#cs-items-cloned > div").clone().appendTo("#cs-items"), Vreel.ItemTransition(), ScrollTrigger.refresh(0), myLazyLoad.update();
				}
			}
		})
	}

}



// Preload  images and fonts
//Promise.any([preloadImages('img'),preloadFonts('Satoshi-Bold')]).then(() => {
function fadeOutEffect(target) {
	$(target).fadeIn();

}
